import { Box, Button, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import { CircleCheckIcon } from '@core/assets/icons/circle_check'
import deleteImage from '@core/assets/images/delete_icon.svg'
import fileImage from '@core/assets/images/file.svg'
import fileJpg from '@core/assets/images/fileJpg.svg'
import filePdf from '@core/assets/images/filePdf.svg'
import filePng from '@core/assets/images/filePng.svg'
import noSupport from '@core/assets/images/file_no_support.svg'
import { ServicesUploadFiles } from '@core/components/ApplyJobDropzone/ServicesUploadFiles'
import { useFormikContext } from 'formik'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

interface Props {
    label?: string
    description: string | ReactNode
    accept: { [key: string]: string[] }
    maxFiles?: number
    onUpload?: (value: FileType[]) => void
    onUploadCv?: (value: FileType[]) => void
    onUploadAttachments?: (value: FileType[]) => void
    useCustomInner?: boolean
    height?: string
    value?: FileType
    maxSize?: number
    applyFiles?: FileType[] | FileType | null
    onApplyFilesDelete?: (file: FileType) => void
    type: 'cv' | 'attachments'
    attachmentErrors?: { name: string; ext: string; base64: string }[] | null
    isAlternative?: boolean
    errorString?: string | null
}

type FileType = {
    base64: string
    ext: string
    name: string
}

interface ButtonArrowIconProps {
    isUp?: boolean
}

const ButtonArrowIcon: FC<ButtonArrowIconProps> = ({ isUp = false }) => {
    return (
        <Box
            w={'8px'}
            h={'8px'}
            borderWidth={'2px'}
            borderStyle={'solid'}
            borderColor={'text.main'}
            borderLeftColor={'transparent'}
            borderBottomColor={'transparent'}
            transform={isUp ? 'rotate(-45deg) translate(50%, 100%)' : 'rotate(135deg) translate(-100%, -75%)'}
        />
    )
}

export const ApplyJobDropzone = ({
    label,
    description,
    accept,
    maxFiles = 1,
    onUpload,
    onUploadCv,
    onUploadAttachments,
    useCustomInner = false,
    height,
    maxSize = 30000000,
    applyFiles,
    onApplyFilesDelete,
    type,
    attachmentErrors,
    errorString = null,
    isAlternative = false
}: Props): JSX.Element => {
    const { errors } = useFormikContext()
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(() => {
        const isFiveMax = maxFiles === 5 ? t('6450') : `Max files is ${maxFiles}!`
        return errorString || (applyFiles && Array.isArray(applyFiles) && applyFiles.length > maxFiles ? isFiveMax : null)
    })

    const bytesToMegabytes = (bytes: number) => Math.ceil(bytes / (1024 * 1024));

    const maxSizeMB = bytesToMegabytes(maxSize); // Calculate max size in megabytes


    useEffect(() => {
        const isFiveMax = maxFiles === 5 ? t('6450') : `Max files is ${maxFiles}!`
        setErrorMessage(errorString || (applyFiles && Array.isArray(applyFiles) && applyFiles.length > maxFiles ? isFiveMax : null))
    }, [applyFiles, maxFiles, errorString]);


    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setErrorMessage(null);

            const totalFiles = (Array.isArray(applyFiles) ? applyFiles.length : 0) + acceptedFiles.length;
            if (totalFiles > maxFiles) {
                const errorMsg = maxFiles === 5 ? t('6450') : `Max files is ${maxFiles}!`;
                setErrorMessage(errorMsg);
                return;
            }

            const oversizedFiles = acceptedFiles.filter((file) => file.size > maxSize);
            if (oversizedFiles.length > 0) {
                setErrorMessage(`${t('8563')} ${maxSizeMB} MB`);
                return;
            }

            const filtered = acceptedFiles.filter((file: File) => {
                const isExist =
                    applyFiles &&
                    Array.isArray(applyFiles) &&
                    applyFiles.find((item: FileType) => item.name === file.name);
                return !isExist;
            });

            const convertFile = async (file: File | any) => {
                const base64 = await convertToBase64(file)
                return Object.assign(
                    {},
                    {
                        // @ts-ignore
                        base64: base64.split('base64,')[1],
                        ext: file.path.split('.').pop(),
                        name: file.path,
                    }
                )
            }

            const promises = filtered.map((file: File) => convertFile(file));

            Promise.all(promises).then((files: FileType[]) => {
                onUpload && onUpload(files);
            });
        },
        [applyFiles, onUpload, maxFiles, maxSize, t]
    );

    const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections } = useDropzone({
        onDrop,
        multiple: maxFiles > 1,
        maxSize: maxSize,
        maxFiles: maxFiles,
        accept: accept,
    })

    const getImage = (type: string) => {
        switch (type) {
            case 'image/png':
                return filePng
            case 'image/jpg':
                return fileJpg
            case 'image/jpeg':
                return fileJpg
            case 'application/pdf':
                return filePdf
            case 'png':
                return filePng
            case 'jpg':
                return fileJpg
            case 'jpeg':
                return fileJpg
            case 'pdf':
                return filePdf
            default:
                return fileImage
        }
    }

    const removeApplyFile = (file: FileType) => {
        setErrorMessage(null)
        // const newFiles = (Array.isArray(applyFiles) && [...applyFiles]) || [applyFiles]
        // newFiles.splice(newFiles.indexOf(file), 1)
        onApplyFilesDelete && onApplyFilesDelete(file)
    }

    const renderApplyFiles = () => {
        if (!applyFiles) {
            return null
        }

        const applyFilesArray = Array.isArray(applyFiles) ? applyFiles : [applyFiles]
        return applyFilesArray.map((file: FileType, idx) => {
            return (
                <VStack
                    className={'ProfilePage-attachments-item'}
                    w={'full'}
                    alignItems={'flex-start'}
                    key={file.base64}
                    // key={`${file.base64}-${idx}`}
                    _last={{ gridColumn: applyFilesArray.length % 2 === 1 ? 'span 2' : 'span 1' }}
                >
                    <HStack
                        key={file.name}
                        gap={2}
                        alignItems={'center'}
                        justifyContent={'center'}
                        px={{ base: 0, xl: isAlternative ? 0 : 4 }}
                        w={'full'}
                        cursor={'pointer'}
                        role={'group'}
                    >
                        <Image
                            src={getImage(file.ext)}
                            alt={`${t('1896')}`}
                        />
                        <Box flex={'1 1 0%'} mr={'auto'} overflow={'hidden'} >
                            <Text
                                fontSize={'sm'}
                                color={'text.main'}
                                wordBreak={'break-word'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textOverflow={'ellipsis'}
                                _groupHover={{ color: 'text.link' }}
                            >
                                {file.name}
                            </Text>
                        </Box>
                        <Box w={'40px'} h={'40px'} role={'group'}>
                            {!isAlternative && <IconButton
                                bg={'transparent'}
                                _groupHover={{ display: 'none' }}
                                _hover={{ background: 'transparent' }}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                icon={<CircleCheckIcon w={'20'} h={'20'} />}
                                aria-label={'button'}
                            />}
                            <IconButton
                                bg={'transparent'}
                                _hover={{ background: 'transparent' }}
                                _groupHover={{ display: 'flex' }}
                                display={'none'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                textColor={'center'}
                                icon={<Image src={deleteImage} alt={`${t('394')}`} />}
                                onClick={() => removeApplyFile(file)}
                                aria-label={'button'}
                            />
                        </Box>
                    </HStack>
                    {/*<Box key={file.name}>*/}
                    <Box>
                        {attachmentErrors && attachmentErrors[idx]?.ext && (
                            <Text fontSize={'xs'} color={'text.error'}>
                                {attachmentErrors[idx].ext[0]}
                            </Text>
                        )}
                        {attachmentErrors && attachmentErrors[idx]?.base64 && (
                            <Text fontSize={'xs'} color={'text.error'}>
                                {attachmentErrors[idx].base64[0]}
                            </Text>
                        )}
                        {attachmentErrors &&
                            attachmentErrors[idx]?.name &&
                            !attachmentErrors[idx]?.ext && (
                                <Text fontSize={'xs'} color={'text.error'}>
                                    {attachmentErrors[idx].name[0]}
                                </Text>
                            )}
                        {errors?.cv?.base64 && type === 'cv' && (
                            <Text fontSize={'xs'} color={'text.error'}>
                                {errors?.cv?.base64}
                            </Text>
                        )}
                        {errors?.cv?.ext && type === 'cv' && (
                            <Text fontSize={'xs'} color={'text.error'}>
                                {errors?.cv?.ext}
                            </Text>
                        )}
                        {errors?.cv?.name && type === 'cv' && (
                            <Text fontSize={'xs'} color={'text.error'}>
                                {errors?.cv?.name}
                            </Text>
                        )}
                    </Box>
                </VStack>
            )
        })
    }

    const convertToBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()

            fileReader.onload = (event) => {
                resolve(fileReader.result)
            }

            fileReader.onerror = (error) => {
                reject(error)
            }

            fileReader.readAsDataURL(file)
        })
    }

    const getBorderColor = () => {
        if (isDragActive || isDragAccept) {
            return 'formElements.bgActive';
        } else if (isDragReject || fileRejections.length > 0) {
            return 'state.error1';
        } else if (errorMessage) {
            return 'state.error1';
        } else {
            return 'formElements.borderDefault';
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} gap={'3'}>
            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                {label}
            </Text>
            <Box
                {...getRootProps({ className: 'dropzone' })}
                display={'flex'}
                justifyContent={useCustomInner ? 'center' : 'start'}
                alignItems={'center'}
                flex={1}
                py={{ base: 2, xl: '23px' }}
                px={{ base: 2, xl: 4 }}
                borderWidth={1}
                borderColor={getBorderColor()}
                borderStyle={'dashed'}
                borderRadius={'4px'}
                bg={isDragActive ? (isDragAccept ? 'calendarState.todaySecondary' : 'rgba(255, 71, 71, 0.1)') : 'bg.1'}
                minH={height}
                cursor={'pointer'}
            >
                <input  {...getInputProps()} />

                {isDragReject && (
                    <HStack gap={3}>
                        <Image src={noSupport} alt={`${t('3345')}`} />
                        <Text maxW={'320px'} fontSize={'xs'} color={'text.thirdly'} wordBreak={'break-all'}>
                            {t('7034')}
                        </Text>
                    </HStack>
                )}
                {isDragActive && !isDragReject && (
                    <HStack gap={3}>
                        <Image maxW={'18px'} src={fileImage} alt={`${t('1895')}`} />
                        <Text maxW={'320px'} fontSize={'xs'} color={'text.thirdly'} wordBreak={'break-all'}>
                            {isDragActive ? `${t('6795')}` : description}
                        </Text>
                    </HStack>
                )}

                {!isDragActive && (
                    <HStack justifyContent={'space-between'} alignItems={'center'} w={'full'}>
                        <HStack gap={3}>
                            <Image src={fileImage} alt={`${t('1895')}`} />
                            <Text
                                maxW={'320px'}
                                fontSize={'sm'}
                                color={'text.thirdly'}
                                lineHeight={5}
                                wordBreak={'break-word'}
                            >
                                {description}
                            </Text>
                        </HStack>
                        <ServicesUploadFiles
                            type={type}
                            onUploadCv={(file) => onUploadCv && onUploadCv(file)}
                            onUploadAttachments={(file) => onUploadAttachments && onUploadAttachments(file)}
                        />
                    </HStack>
                )}
            </Box>
            {fileRejections.map((fileRejection: any) => {
                if (fileRejection.errors[0].code === 'file-too-large') {
                    return (
                        <Text fontSize={'xs'} color={'text.error'}>
                            {t('8563', { size: maxSizeMB })}
                        </Text>
                    )
                }
                if (fileRejection.errors[0].code === 'file-invalid-type') {
                    return (
                        <Text fontSize={'xs'} color={'text.error'}>
                            {`${t('7416')} (${fileRejection.file.name})`}
                        </Text>
                    )
                }
            })}
            {fileRejections.length > maxFiles && (
                <Text fontSize={'xs'} color={'text.error'}>
                    {/*Max files is {maxFiles}!*/}
                    {maxFiles === 5 ? t('6450') : `Max files is ${maxFiles}`}
                </Text>
            )}
            {errorMessage && (
                <Text fontSize={'xs'} color={'text.error'}>
                    {errorMessage}
                </Text>)
            }
            {Array.isArray(applyFiles) && applyFiles.length > maxFiles && (
                <Text fontSize={'xs'} color={'text.error'}>
                    {maxFiles === 5 ? t('6450') : `Max files is ${maxFiles}`}
                </Text>
            )}
            {applyFiles && !isAlternative && <VStack className={'ProfilePage-attachments-wrap-non'} gap={2} alignItems={'start'}>{renderApplyFiles()}</VStack>}
            {applyFiles && isAlternative &&
                <VStack
                    className={'ProfilePage-attachments-wrap'}
                    display={'grid'}
                    gridTemplateColumns={'repeat(auto-fill, minmax(200px, 1fr))'}
                    gap={2}
                    alignItems={'start'}

                >
                    {renderApplyFiles()}
                </VStack>}
            {isAlternative && Array.isArray(applyFiles) && applyFiles.length > 0 && (
                <Box
                    className={'ProfilePage-expanded-btn'}
                    display={'none'}
                >
                    <Button
                        onClick={() => setExpanded(!expanded)}
                        fontSize={'sm'}
                        gap={2}
                        fontWeight={'medium'}
                        leftIcon={<ButtonArrowIcon isUp={expanded} />}
                        border={'1px solid'}
                        borderColor={'gray.200'}
                        borderRadius={'full'}
                        bg={'transparent'}
                        _hover={{ bg: 'gray.100' }}
                        _focus={{ boxShadow: 'none' }}
                        px={4}
                        py={2}
                    >
                        {expanded ? t('6214') : t('6213')}
                    </Button>
                </Box>
            )}
        </Box>
    )
}

