import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import enGb from '@core/utils/translations/en-GB.json'

if (process.env.ENV === 'local') {
    const resources = {
        'en-GB': {
            translation: enGb,
        },
    }
    i18next.use(initReactI18next).init({
        resources,
        lng: 'en-GB',
    })
} else {
    i18next.use(Backend).init({
        load: 'currentOnly',
        lng: document.documentElement.lang,
        fallbackLng: false,
        backend: {
            loadPath: '/translations/{{lng}}.json?v=1.0.84',
        },
    })
    i18next.use(initReactI18next).init({})
}

export default i18next
