import { absenceApi } from "@core/app/api/absenceApi";
import { RootState } from "@core/app/store/store";
import { AbsenceData, EmployeeSettings, ShortJob } from "@core/app/types/absenceApiType";
import { CalendarHoliday, CalendarTrip, Job, LoggedWorkByIdData, LoggedWorkItem } from "@core/app/types/logworkApiType";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface AbsenceState {
    asideWidth: number
    currentYear: number
    view: 'view' | 'create'
    absenceData: AbsenceData | null
    absenceDays: LoggedWorkItem[]
    employeeSettings: EmployeeSettings | null
    jobs: Job[]
    shortJobs: ShortJob[]
    absenceById: LoggedWorkByIdData | null
    loading: boolean
    successDataLoaded: boolean
    trips: CalendarTrip[]
    holidays: CalendarHoliday[]
}

const initialState: AbsenceState = {
    asideWidth: 0,
    currentYear: dayjs().year(),
    view: (localStorage.getItem('viewState') as 'view' | 'create') || 'view',
    absenceData: null,
    absenceDays: [],
    jobs: [],
    shortJobs: [],
    trips: [],
    holidays: [],
    absenceById: null,
    successDataLoaded: false,
    loading: false,
    employeeSettings: null
}

const absenceSlice = createSlice({
    name: 'absence',
    initialState,
    reducers: {
        setAsideWidth(state, action) {
            state.asideWidth = action.payload
        },
        setCurrentYear(state, action) {
            state.currentYear = action.payload
        },
        createAbsence(state) {
            state.view = 'create'
            state.jobs = []
            localStorage.setItem('viewState', 'create')
        },
        closeAbsence(state) {
            state.view = 'view'
            localStorage.setItem('viewState', 'view')
        }
    },
    extraReducers(builder) {
        builder.addMatcher(absenceApi.endpoints.getData.matchPending, (state) => {
            state.loading = true
        })
        builder.addMatcher(absenceApi.endpoints.getData.matchRejected, (state) => {
            state.loading = false
        })
        builder.addMatcher(absenceApi.endpoints.getData.matchFulfilled, (state, action) => {
            const { absenceData, work, jobs, trips, nationalHolidays, employeeSettings } = action.payload
            if (absenceData) {
                state.absenceData = action.payload.absenceData
            }
            if (work?.loggedWork) {
                state.absenceDays = Object.values(work.loggedWork).flat()
            }
            if (jobs) {
                state.jobs = action.payload.jobs
            }
            if (trips) {
                state.trips = action.payload.trips
            }
            if (nationalHolidays) {
                state.holidays = action.payload.nationalHolidays
            }
            if (employeeSettings) {
                state.employeeSettings = employeeSettings
            }
            
            state.loading = false
            state.successDataLoaded = true
        }),
        builder.addMatcher(absenceApi.endpoints.getAbsenceById.matchFulfilled, (state, action) => {
            state.absenceById = action.payload
        })
    }
})

export default absenceSlice.reducer
export const { setAsideWidth, setCurrentYear, createAbsence, closeAbsence } = absenceSlice.actions
export const storeAsideWidth = (state: RootState) => state.absence.asideWidth
export const storeCurrentYear = (state: RootState) => state.absence.currentYear
export const storeAbsenceData = (state: RootState) => state.absence.absenceData
export const storeAbsenceLoading = (state: RootState) => state.absence.loading
export const storeAbsenceDays = (state: RootState) => state.absence.absenceDays
export const storeShortJobs = (state: RootState) => state.absence.shortJobs
export const storeAbsenceById = (state: RootState) => state.absence.absenceById
export const storeJobs = (state: RootState) => state.absence.jobs
export const storeView = (state: RootState) => state.absence.view
export const storeTrips = (state: RootState) => state.absence.trips
export const storeHolidays = (state: RootState) => state.absence.holidays
export const storeSuccessDataLoaded = (state: RootState) => state.absence.successDataLoaded
export const storeEmployeeSettings = (state: RootState) => state.absence.employeeSettings