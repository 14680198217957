import { loggedWorkApi } from "@core/app/api/loggedWorkApi";
import { RootState } from "@core/app/store/store";
import { ShortJob } from "@core/app/types/absenceApiType";
import { Job, LoggedWorkItem } from "@core/app/types/logworkApiType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export type ColumnTypes = 
        'date'
        | 'job'
        | 'subProject'
        | 'article'
        | 'articleGroup'
        | 'status'
        | 'info'
        | 'duration'
        | 'break'
        | 'qty'
        | 'salary'
        | 'invoice'

interface LoggedWorkState {
    asideWidth: number
    choosedDate: string
    viewType: 'week' | 'month' | 'year'
    loggedWork: LoggedWorkItem[]
    loading: boolean
    tableColumns: { type: ColumnTypes, label: string, visibility: boolean, width: string, manuallyHidden: boolean, autoHidden: boolean }[]
    jobs: Job[]
    shortJobs: ShortJob[]
}

const defaultTableColumns = [
    { type: 'date', label: 'Date', visibility: true, width: '133px', manuallyHidden: false, autoHidden: false },
    { type: 'job', label: 'Job', visibility: true, width: '271px', manuallyHidden: false, autoHidden: false },
    { type: 'subProject', label: 'Sub project', visibility: true, width: '170px', manuallyHidden: false, autoHidden: false },
    { type: 'article', label: 'Article', visibility: true, width: '271px', manuallyHidden: false, autoHidden: false },
    { type: 'articleGroup', label: 'Article group', visibility: true, width: '147px', manuallyHidden: false, autoHidden: false },
    { type: 'status', label: 'Status', visibility: true, width: '155px', manuallyHidden: false, autoHidden: false },
    { type: 'info', label: 'Info', visibility: true, width: '125px', manuallyHidden: false, autoHidden: false },
    { type: 'duration', label: 'Duration', visibility: true, width: '108px', manuallyHidden: false, autoHidden: false },
    { type: 'break', label: 'Break', visibility: true, width: '85px', manuallyHidden: false, autoHidden: false },
    { type: 'qty', label: 'Qty', visibility: true, width: '85px', manuallyHidden: false, autoHidden: false },
    { type: 'salary', label: 'Salary', visibility: true, width: '95px', manuallyHidden: false, autoHidden: false },
    { type: 'invoice', label: 'Invoice', visibility: true, width: '85px', manuallyHidden: false, autoHidden: false },
]

const initialState: LoggedWorkState = {
    asideWidth: 0,
    choosedDate: dayjs().toISOString(),
    viewType: 'month',
    loggedWork: [],
    loading: false,
    tableColumns: loadTableColumnsFromLocalStorage(),
    jobs: [],
    shortJobs: []
}

function loadTableColumnsFromLocalStorage() {
    const storedColumns = localStorage.getItem('columns')
    if (storedColumns) {
        try {
            return JSON.parse(storedColumns);
        } catch {
            console.error('Failed to parse table columns from localStorage');
        }
    }
    // default values
    return defaultTableColumns
}

function saveTableColumnsToLocalStorage(columns: LoggedWorkState['tableColumns']) {
    localStorage.setItem('columns', JSON.stringify(columns));
}

const loggedWorkSlice = createSlice({
    name: 'loggedWork',
    initialState,
    reducers: {
        setAsideWidth(state, action) {
            state.asideWidth = action.payload
        },
        setChoosedDate(state, action) {
            state.choosedDate = action.payload
        },
        setViewType(state, action) {
            state.viewType = action.payload
        },
        updateTableColumns(state, action: PayloadAction<{ type: string; visibility?: boolean; newIndex?: number }>) {
            const { type, visibility, newIndex } = action.payload
            const columnIndex = state.tableColumns.findIndex((col) => col.type === type)

            if (columnIndex !== -1) {
                if (visibility !== undefined) {
                    state.tableColumns[columnIndex].visibility = visibility
                    state.tableColumns[columnIndex].manuallyHidden = !visibility
                }

                if (newIndex !== undefined && newIndex >= 0 && newIndex < state.tableColumns.length) {
                    const [movedColumn] = state.tableColumns.splice(columnIndex, 1)
                    state.tableColumns.splice(newIndex, 0, movedColumn)
                }

                saveTableColumnsToLocalStorage(state.tableColumns)
            }
        },
    },
    extraReducers(builder) {
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchPending, (state) => {
            state.loading = true
        }),
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchRejected, (state) => {
            state.loading = false
        }),
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchFulfilled, (state, action) => {
            const { work, jobs } = action.payload
            if (work) {
                const loggedWork = Object.values(work.loggedWork).flat() as LoggedWorkItem[]
                state.loggedWork = loggedWork

                const uniqueJobIds = new Set(loggedWork.map((item) => item.jobId))
                const hasArticleGroups = loggedWork.some((item) => item.articleGroupId !== 0)
                const hasSalary = loggedWork.some((item) => item.salary !== 'hidden')
                const hasInvoice = loggedWork.some((item) => item.invoice !== 'hidden')
                const hasChildProjects = loggedWork.some((item) => item.childProjectId !== 0)

                state.tableColumns.forEach((column) => {
                    if (!column.manuallyHidden) {
                        switch (column.type) {
                            case 'job':
                                column.autoHidden = uniqueJobIds.size <= 1
                                break
                            case 'articleGroup':
                                column.autoHidden = !hasArticleGroups
                                break
                            case 'salary':
                                column.autoHidden = !hasSalary
                                break
                            case 'invoice':
                                column.autoHidden = !hasInvoice
                                break
                            case 'subProject':
                                column.autoHidden = !hasChildProjects
                                break
                        }
                    }

                    column.visibility = !column.autoHidden && !column.manuallyHidden
                })

                saveTableColumnsToLocalStorage(state.tableColumns)
            }

            if (jobs) {
                state.jobs = jobs
            }
            state.loading = false
        })
        
    }
})

export default loggedWorkSlice.reducer
export const { setAsideWidth, setChoosedDate, setViewType, updateTableColumns } = loggedWorkSlice.actions
export const storeAsideWidth = (state: RootState) => state.loggedWork.asideWidth
export const storeChoosedDate = (state: RootState) => state.loggedWork.choosedDate
export const storeViewType = (state: RootState) => state.loggedWork.viewType
export const storeLoggedWork = (state: RootState) => state.loggedWork.loggedWork
export const storeLoading = (state: RootState) => state.loggedWork.loading
export const storeTableColumns = (state: RootState) => state.loggedWork.tableColumns
export const storeJobs = (state: RootState) => state.loggedWork.jobs
export const storeShortJobs = (state: RootState) => state.loggedWork.shortJobs
