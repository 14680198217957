import { AbsenceRequest, AbsenceResponse, AbsenceResponseData, LogAbsence, UpdateAbsence } from '@core/app/types/absenceApiType';
import { Locale } from '@core/app/types/globalApiType';
import { LoggedWorkByIdData, LoggedWorkByIdResponse } from '@core/app/types/logworkApiType';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const absenceApi = createApi({
    reducerPath: 'absenceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin',
    }),
    tagTypes: ['AbsenceData', 'AbsenceDataById'],
    endpoints: (builder) => ({
        getLocale: builder.query<Locale, void>({
            query: () => ({
                url: '/locale',
                method: 'GET',
            }),
        }),
        getData: builder.query<AbsenceResponseData, AbsenceRequest>({
            query: (arg) => {
                const { fromDate, toDate, scopes, workArticleIds, workSort, work } = arg
                return {
                    url: '/employee/calendar/v2',
                    method: 'GET',
                    params: {
                        fromDate,
                        toDate,
                        scopes,
                        workArticleIds,
                        workSort,
                        work
                    }
                }
            },
            transformResponse: (response: AbsenceResponse) => {
                return response.data
            },
            providesTags: ['AbsenceData'],
        }),
        getAbsenceById: builder.query<LoggedWorkByIdData, number>({
            query: (id) => ({
                url: `/employee/work/${id}`,
                method: 'GET',
            }),
            providesTags: ['AbsenceDataById'],
            transformResponse: (response: LoggedWorkByIdResponse) => response.data,
        }),
        deleteAbsence: builder.mutation<void, { ids: number[] }>({
            query: (body) => ({
                url: '/employee/work/delete',
                method: 'DELETE',
                body: {
                    ids: body.ids,
                }
            }),
            invalidatesTags: ['AbsenceData'],
        }),
        createAbsence: builder.mutation<void, LogAbsence>({
            query: (body) => ({
                url: '/employee/absence/log/v2',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['AbsenceData'],
        }),
        updateAbsence: builder.mutation<void, UpdateAbsence>({
            query: (body) => ({
                url: `/employee/work/edit/${body.workHourId}/absence`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['AbsenceData', 'AbsenceDataById'],
        }),
        deleteFileFromAbsence: builder.mutation<void, { fileId: number, workHourId: number }>({
            query: (body) => ({
                url: `/employee/work/${body.workHourId}/delete/${body.fileId}`,
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['AbsenceDataById', 'AbsenceData'],
        }),
    }),
})

export const { useGetLocaleQuery, useGetDataQuery, useLazyGetDataQuery, useGetAbsenceByIdQuery, useDeleteAbsenceMutation, useCreateAbsenceMutation, useUpdateAbsenceMutation, useDeleteFileFromAbsenceMutation } = absenceApi
